import React from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import Emoji from '../components/Emoji'
import OpenSourceInvolment from '../components/OpenSourceInvolvment'
import SEO from '../components/seo'

export default ({ data }) => (
  <Layout className="about">
    <SEO
      title="About"
      lang="en"
      keywords={[
        'jonas pauthier',
        'software engineer',
        'lyon',
        'javascript',
        'reasonml',
        'nodejs',
        'react',
        'web development',
      ]}
    />
    <div
      css={css`
        text-align: center;
      `}
    >
      <Image
        css={css`
          min-width: 50px;
          border-radius: 100%;
        `}
        fixed={data.avatar.childImageSharp.fixed}
        alt="profile picture"
      />
    </div>
    <h1
      css={css`
        text-align: center;
      `}
    >
      Hello there! <Emoji symbol="👋" /> I'm Jonas
    </h1>
    <p>
      I'm a Computer Science engineer, specialized in web development. I'm also
      passionate about <strong>Open Source</strong>, I try to get involved as
      much as possible. I'm one of the two technical co-founder of{' '}
      <a href="https://dixeed.com" target="_blank" rel="noopener">
        Dixeed
      </a>{' '}
      where we help companies building their web solutions from start to finish.
      <br />
      <br />
      If I'm not in front of my computer building things you can find me at the
      gym doing rock-climbing and weight-lifting.
    </p>

    <h2>Open Source involments</h2>
    <OpenSourceInvolment
      image={data.hapi.publicURL}
      imageLink="https://github.com/hapijs/hapi"
      alt="hapi project logo"
    >
      <p
        css={css`
          margin: 0;
        `}
      >
        <a href="https://github.com/hapijs" target="_blank" rel="noopener">
          hapi.js GitHub organization
        </a>
        <span> TSC member</span>
        <br />
        <span>Previously hapijs.com lead maintainer</span>
      </p>
    </OpenSourceInvolment>

    <OpenSourceInvolment
      image={data.hapipal.publicURL}
      imageLink="https://hapipal.com/"
      alt="hapipal logo"
    >
      <p
        css={css`
          margin: 0;
        `}
      >
        <a href="https://github.com/hapipal" target="_blank" rel="noopener">
          hapipal GitHub organization
        </a>
        <span> core team member</span>
      </p>
    </OpenSourceInvolment>
  </Layout>
)

export const pageQuery = graphql`
  query About {
    hapi: file(base: { eq: "hapi.svg" }) {
      publicURL
    }
    hapipal: file(base: { eq: "pal.svg" }) {
      publicURL
    }
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
