import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'

const OpenSourceInvolvment = ({ image, imageLink, alt, children }) => {
  const img = (
    <img
      src={image}
      alt={alt}
      css={css`
        margin: 0;
        margin-right: ${rhythm(1)};
        width: 100px;
        height: auto;
      `}
    />
  )
  return (
    <div
      css={theme => css`
        display: flex;
        align-items: center;
        margin-bottom: ${rhythm(1)};

        ${theme.breakpoints.mobile} {
          flex-direction: column;
        }
      `}
    >
      {imageLink ? (
        <a
          href={imageLink}
          target="_blank"
          rel="nooponer"
          className="clear-link"
        >
          {img}
        </a>
      ) : (
        img
      )}
      <div>{children}</div>
    </div>
  )
}

OpenSourceInvolvment.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  alt: PropTypes.string,
  imageLink: PropTypes.string,
}

export default OpenSourceInvolvment
