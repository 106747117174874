import React from 'react'
import PropTypes from 'prop-types'

const Emoji = ({ label, symbol, className }) => (
  <span
    className={className}
    role="img"
    aria-label={label || ''}
    aria-hidden={!!label}
  >
    {symbol}
  </span>
)

Emoji.propTypes = {
  label: PropTypes.string,
  symbol: PropTypes.string.isRequired,
}

export default Emoji
